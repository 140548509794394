import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export const TransactionsList = () => {
  return (
    <>
    <section className="listings_three-page container my-4 one">
            <div className="row">
                <div className="col-xl-12 border p-4">
                    {/* Start Place One Single */}
                            <div className="row">
                                <div className="col-xl-2 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one">
                                        <img src='https://api.medisagar.com/uploads/1704117153274.png'  />
                                    </div>
                                </div>

                                <div className="col-xl-7 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one my-3">
                                        <h4>Sanjay Kumar</h4>
                                        <p className='green'>Recived</p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one my-3">
                                        <h4>$146</h4>
                                        <p>4:37 PM</p>
                                    </div>
                                </div>
                            </div>
                    {/* End Place One Single */}
                </div>

                <div className="col-xl-12 border p-4">
                    {/* Start Place One Single */}
                            <div className="row">
                                <div className="col-xl-2 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one">
                                        <img src='https://api.medisagar.com/uploads/1704117153274.png'/>
                                    </div>
                                </div>

                                <div className="col-xl-7 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one my-3">
                                        <h4>Suleman Kadri</h4>
                                        <p className='green'>Recived</p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one my-3">
                                        <h4>$15446</h4>
                                        <p>4:37 PM</p>
                                    </div>
                                </div>
                            </div>
                    {/* End Place One Single */}
                </div>

                <div className="col-xl-12 border p-4">
                    {/* Start Place One Single */}
                            <div className="row">
                                <div className="col-xl-2 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one">
                                        <img src='https://api.medisagar.com/uploads/1704117153274.png' />
                                    </div>
                                </div>

                                <div className="col-xl-7 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one my-3">
                                        <h4>Abrar Khan</h4>
                                        <p className='grey'>Pending</p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-lg-12 col-md-12">
                                    <div className="title-sky-place-one my-3">
                                        <h4>$46</h4>
                                        <p>4:37 PM</p>
                                    </div>
                                </div>
                            </div>
                    {/* End Place One Single */}
                </div>
            </div>
        </section>
    </>
  )
}
