import { Button, Card, Col, Form, Input, Modal, Row, Select } from 'antd'
import React, { useState } from 'react'
import { notifyUser } from '../../utils/notification-service';

function InviteModal(props) {


    const [loading, isLoading] = useState(false);
    const onFinish = (values) => {


        console.log({ values })


        let args = {};



        args = {
            firstName: 'Medisagar',
            lastName: '',
            emails: values.email,
            type: 'admin'
        }


        isLoading(true);
        props.sendInvitation(args)
            .then((resp) => {
                isLoading(false);
                if (resp.messageId) {
                    notifyUser('Invite has been sent', 'success', 3);
                    props.setInvitationModal(false)
                }
                else {
                    notifyUser('Failed to send email', 'error', 3);
                }

            }).catch((err) => {
                isLoading(false);
                notifyUser('Failed to send email', 'error', 3);
            })

    }

    return (
        <Modal
            open={props.open}
            onCancel={() => props.setInvitationModal(false)}
            footer={false}

        >

            <Card title='Enter your email, we will send an invite' style={{ maginTop: '5%' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={[10, 10]}>
                        <Col xs={24} sm={24} md={24} xl={24}>
                            <Form.Item name='email' rules={[{
                                required: true,
                                type: 'email',
                                message: 'Please enter a valid email address'
                            }]}>

                                <Input
                                    placeholder="Please enter email"
                                // ref={inputRef}
                                // value={name}
                                // onChange={onNameChange}
                                // onChange={(e) => e.stopPropagation()}
                                />




                            </Form.Item>
                        </Col>
                    </Row>


                    <Row gutter={[10, 10]}>
                        <Col xs={12} sm={8} md={12} xl={6}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading} >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Modal>
    )
}

export default InviteModal