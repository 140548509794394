import React from 'react';
import { Link } from 'react-router-dom';
import withRouter from '../../utils/withRouterCustom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import config from '../../config';
import { Spin } from 'antd';
class AboutOne extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            otherInfo: null,
            loading: true
        }
    }


    componentDidMount() {


        this.props.getOtherInfo(1).then((resp) => {

            if (resp && !resp.error) {
                this.setState({ otherInfo: JSON.parse(resp.configData), loading: false });
            }
        }).catch((ex) => {
            console.log({ ex });
        })



    }


    render() {
        let { otherInfo, loading } = this.state;
        let publicUrl = process.env.PUBLIC_URL + '/'
        return (
            <>
                {/* Start About One */}
                <section className="about-one about-one--two">
                    {!loading && otherInfo &&

                        <Spin spinning={loading}>
                            <div className="shape4"><img src={publicUrl + "assets/images/shapes/about-v1-shape4.png"} alt="#" /></div>
                            <div className="container">
                                <div className="row">
                                    {/* Start About One Left */}
                                    <div className="col-xl-6">
                                        <div className="about-one__left">
                                            <div className="about-one__left-img">
                                                <div className="shape1 float-bob-y"></div>
                                                <div className="shape2 float-bob-y"></div>
                                                <div className="shape3 float-bob-y"></div>
                                                <img src={otherInfo.aboutImage1 && otherInfo.aboutImage1[0] && otherInfo.aboutImage1[0].url ? (config.APIURL1 + '/' + (otherInfo && otherInfo.aboutImage1 && otherInfo.aboutImage1[0] && otherInfo.aboutImage1[0].url)) : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* End About One Left */}

                                    {/* Start About One Right */}
                                    <div className="col-xl-6">
                                        <div className="about-one__right">
                                            <div className="about-one__right-title">
                                                <h2>{otherInfo.about1Title}</h2>
                                            </div>

                                            <div className="about-one__right-text1">
                                                <p>{otherInfo.aboutUsBody1}</p>
                                            </div>

                                            <div className="about-one__right-text3">
                                                <div className="about-one__right-text3-icon" >
                                                    <span className="icon-budget"></span>
                                                </div>
                                                <div className="about-one__right-text3-content" style={{marginTop:'20px',marginBottom:'20px'}}>
                                                    <h2>{otherInfo.about2Title}</h2>
                                                    <p>{otherInfo.aboutUsBody2} </p>
                                                </div>
                                            </div>

                                            {/* <div className="about-one__right-btn">
                                        <Link to={process.env.PUBLIC_URL + `/`} className="thm-btn">{}</Link>
                                    </div> */}
                                        </div>
                                    </div>
                                    {/* End About One Right */}
                                </div>
                            </div>
                        </Spin>}

                </section>
                {/* End About One */}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        AboutOne
    )
);

