import React, { useRef, useState } from 'react';
import { Button, Modal, Form, Input, Row, Col, DatePicker, Switch, Radio, Spin } from 'antd';
import { MdVerifiedUser } from 'react-icons/md';
import { FaRegThumbsUp } from 'react-icons/fa';
import moment from 'moment'
import config from '../../config';
import { getStorageUser } from '../../utils/auth';
import { notifyUser } from '../../utils/notification-service';
import { bindActionCreators } from 'redux';
import withRouter from '../../utils/withRouterCustom';
import { connect } from 'react-redux';
import * as appActions from '../../redux/actions/app-actions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import dayjs from 'dayjs';


const DateSlider = ({ dates, doctorAppointments, itemDetails, userDetails, appointmentOTPGenerate, appointmentVerifyAndCreate, navigate, router }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  if (userDetails) {


    userDetails.dob = userDetails.dob && userDetails.dob !== null ? dayjs(userDetails.dob) : dayjs();
    userDetails.phone = '';
  }
  let [formData, setFormData] = useState(userDetails);

  console.log('lele', { router })
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    if (userDetails) {


      userDetails.dob = userDetails.dob && userDetails.dob !== null ? dayjs(userDetails.dob) : dayjs();
      userDetails.phone = '';
    }
    setFormData(userDetails);
    setStep(1);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  return (
    <div>
      <div className='slot-container'>
        <div className='tab-container'>
          {dates && Object.keys(dates).map((key, idx, arr) => (
            // {dates.map((date) => (
            <div
              key={key}
              onClick={() => handleDateClick(key)}
              className={selectedDate === key ? 'selected tab' : 'tab'}
            >
              {key}
              <br />
              <span>{dates[key].length} Slots Available</span>
            </div>
          ))}
        </div>
      </div>

      <div className='tab-content'>
        {selectedDate && (
          <TabPanel step={step} setStep={setStep} formData={formData} setFormData={setFormData} appointmentOTPGenerate={appointmentOTPGenerate} router={router} navigate={navigate} appointmentVerifyAndCreate={appointmentVerifyAndCreate} userDetails={userDetails} selectedDate={selectedDate} handleCancel={handleCancel} handleOk={handleOk} showModal={showModal} isModalOpen={isModalOpen} dates={dates} doctorAppointments={doctorAppointments} itemDetails={itemDetails} />
        )}
      </div>
    </div>
  );
};

const TabPanel = ({step,setStep, formData, setFormData, navigate, router, selectedDate, showModal, isModalOpen, handleOk, handleCancel, dates, doctorAppointments, itemDetails, userDetails, appointmentOTPGenerate, appointmentVerifyAndCreate }) => {
  // Implement your tab panel content based on the selected date


  const [other, setOther] = useState(false);
  const [loading, setLoading] = useState(false);

  const [time, setTime] = useState("");
  const [countryCode, setCountryCode] = useState();
  const formRef = useRef();




  const onFinish = (values) => {

    values.dob = values.dob.toISOString();
    console.log({ values })
    // setFormData(values);



    if (step == 1) {
    
      let args = {
        phone: values.phone
      }
      setLoading(true);
      appointmentOTPGenerate(userDetails.id, args).then((resp) => {


        if (resp) {
          setLoading(false);
          notifyUser(resp.message, 'success');
          setStep(2);
          setFormData(values);
        }

      }).catch((err) => {
        notifyUser(err.message || "Something went wrong!", 'error', 7);
        setLoading(false);
      })
    }
    else {

      let args = {
        phone: values.phone,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        dob: values.dob,
        date: selectedDate,
        time: time,
        clinic_id: values.clinic_id,
        doctor_id: values.doctor_id,
        OTP: values.OTP,
        doctor_id: itemDetails.id,
        clinic_id: itemDetails.clinic_id,
        userId: userDetails.id

      }
      args.date = moment(args.date, 'ddd, MMM DD').format('MM-DD-YYYY');
      setLoading(true);
      appointmentVerifyAndCreate(args).then((resp) => {


        if (resp) {
          notifyUser(resp.message || "OTP Verified", 'success');
          setStep(2);
          // setFormData(values);
          setLoading(false);
          navigate('/thank-you?id=' + resp.id)
        }

      }).catch((err) => {
        notifyUser(err.message || "Something went wrong!", 'error', 7);
        setLoading(false);
      })
    }

  }
  const checkIfAppointmentAvailable = (date, time) => {

    let count = 0;

    // Combine date and time strings into a single string
    const dateTimeStr = `${date} ${time}`;

    // Parse the combined date-time string into a Moment.js object
    const dateTime = moment(dateTimeStr, 'ddd, MMM D hh:mm A');

    // Get the current date
    const currentDate = moment().format('ddd, MMM D hh:mm A');

    // Check if the parsed date-time is before today
    const isBeforeToday = dateTime.isBefore(currentDate, 'minute');


    for (let i = 0; i < doctorAppointments.length; i++) {
      const appointment = doctorAppointments[i];
      const formattedDate = moment(appointment.date, 'MM-DD-YYYY').format('ddd, MMM DD');
      if (formattedDate === date && appointment.time === time) {
        count++;
      }

    }
    if (isBeforeToday) count = 5;
    return count >= 2 ? false : true
  }



  console.log({ countryCode });
  console.log({ selectedDate })
  return (
    <div>
      <h3>{selectedDate}</h3>
      {/* time slots loop */}
      {dates[selectedDate].map(t =>
        <button style={{
          backgroundColor: !checkIfAppointmentAvailable(selectedDate, t) ? 'grey' : '#150b5c',
          pointerEvents: !checkIfAppointmentAvailable(selectedDate, t) ? 'none' : 'all'

        }} type="button" class="btn btn-info sky-btn m-1" onClick={() => { showModal(); setTime(t) }}>{t}</button>
      )}


      {isModalOpen && <Modal width={1000} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={
        null
      }>
        <div className='row'>
          <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12'>
            <div className='row'>
              <div className='col-3 text-center'>
                <img alt={itemDetails.firstName} src={config.backendURL + itemDetails?.image?.url} width={'80%'} height={'auto'} />
              </div>
              <div className='col-9'>
                <h4>Dr. {itemDetails.firstName} {itemDetails.lastName}</h4>
                <p>{itemDetails.education}</p>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>{itemDetails?.specialities?.map((x, idx) => <><span>{x.name}</span><span style={{ display: idx >= itemDetails.specialities.length - 1 ? 'none' : '' }}>,</span></>)}</div>
                <p>{itemDetails.experience}</p>
              </div>
            </div>
          </div>
          {userDetails &&
            <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12'>
              <Spin spinning={loading}>
                <h5>Enter your details</h5>

                {<Form
                  onFinish={onFinish}
                  name="basic"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 20 }}
                  style={{ maxWidth: 600 }}
                  initialValues={formData}
                  ref={formRef}
                  // onFinish={onFinish}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="First Name"
                        name="firstName"
                        rules={[{ required: true, message: 'Please enter First name!' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[{ required: true, message: 'Please enter Last name!' }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Date of birth"
                        name="dob"
                        rules={[{ required: true, message: 'Please select DOB!' }]}

                      >
                        <DatePicker format={'YYYY/MM/DD'} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[{ required: true, message: 'Please select Gender!' }]}
                      >

                        <Radio.Group>
                          <Radio value={'Male'}>Male</Radio>
                          <Radio value={'Female'}>Female</Radio>

                        </Radio.Group>
                      </Form.Item>

                    </Col>
                    <Col span={24}>
                      {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}

                      <Form.Item
                        style={{ marginBottom: '5px' }}
                        label="Mobile"
                        name="phone"
                        wrapperCol={{ span: 16 }}
                        rules={[{ required: true, message: 'Please enter your Mobile number!' }]}
                      >
                        <PhoneInput
                          country={'np'}
                          enableSearch={true}
                          value={countryCode}
                          onChange={phone => setCountryCode(phone)}
                        />
                        {/* <Input onKeyDown={(event) => {
                            if (event.key === "Backspace" || event.key === "Delete" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
                              return;
                            }
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }} /> */}

                      </Form.Item>
                      {/* </div> */}

                    </Col>

                    {step == 2 && <Col span={24}>
                      <Form.Item
                        label="OTP"
                        name="OTP"
                        wrapperCol={{ span: 16 }}
                        rules={[{ required: true, message: 'Please enter OTP!' }]}
                      >
                        <Input onKeyDown={(event) => {
                          if (event.key === "Backspace" || event.key === "Delete" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
                            return;
                          }
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} />

                      </Form.Item>
                      {/* <span >We will send appointment-related communications on this number.</span> */}
                    </Col>
                    }

                  </Row>
                  <Row style={{ marginTop: '10px' }}>
                    <Col span={24}>
                      <Button danger htmlType='submit'>{step == 1 ? 'Next' : 'Submit'}</Button>
                    </Col>

                  </Row>
                </Form>
                }
                {step == 1 && <Row>
                  <Col span={24} style={{ marginTop: '5px' }}>
                    <Form.Item
                      label="If you are booking appointment for other patient then enable this?"
                      name="otherAppointment"
                    >
                      <Switch checked={other} onChange={(e) => {
                        console.log({ e })
                        setOther(e);
                        if (e) {
                          formRef?.current?.setFieldsValue({ firstName: undefined, lastName: undefined, dob: undefined, gender: undefined, phone: undefined })
                        }
                        else {
                          let _formData = formData;
                          _formData.dob = moment(_formData.dob, 'YYYY/MM/DD');
                          formRef?.current?.setFieldsValue(_formData);
                        }

                      }} />
                    </Form.Item>

                  </Col>
                </Row>
                }
              </Spin>
            </div>
          }

          {!userDetails &&

            <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12'>

              <h5>Please <span style={{ textDecoration: 'underline', color: '#415899', cursor: 'pointer' }} onClick={() => {

                localStorage.setItem('previousURL', router.location.pathname);

                navigate('/login');



              }}>login</span> to make an appointment</h5>
            </div>
          }
        </div>

      </Modal>
      }
    </div>
  );
};

export default DateSlider

