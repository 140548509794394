import  {Types}  from '../constants/user-types';

const initialState = {
    user: null
};
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        
        case Types.SET_USER:
      
            return {...state, user: action.payload}
        default:
            return state;
    }
}