import React from 'react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import ListingOne from '../components/listing/ListingOne';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';

const ListingsOne = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="Listings"
                currentPage="Listings" 
            />
            <ListingOne />
            <FooterOne />
        </>
    )
}

export default ListingsOne;