import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Slider from "react-slick";
import DateSlider from './DateSlider';
import { getStorageUser } from '../../utils/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withRouter from '../../utils/withRouterCustom';
import * as appActions from '../../redux/actions/app-actions';

 class ListingWorkingHours extends React.Component {

    constructor(props){
        super(props);
        this.state={

        }
    }
    render() {
        const currentDate = new Date();
        const dates = [];

        // for (let i = 0; i < 7; i++) {
        //     const date = new Date(currentDate);
        //     date.setDate(currentDate.getDate() + i);
        //     const formattedDate = date.toLocaleDateString('en-US', {
        //     weekday: 'short',
        //     // year: 'numeric',
        //     month: 'short',
        //     day: 'numeric',
        //     });
        //     dates.push(formattedDate);
        // }
        const { itemDetails, availabilityDetails, doctorAppointments } = this.props;
        const user = getStorageUser();
        let userDetails = user ? JSON.parse(user) : null;
  
        return (

            <>
                <div className="listings-details-page__sidebar-single sidebar__working-hours wow animated fadeInUp" data-wow-delay="0.1s">
                    <div className="title">
                        <h2>Pick a time slot</h2>
                        <div className='row'>
                            <div className='col-8'>
                                <p>Clinic Appointment</p>
                            </div>
                            <div className='col-4'>
                                <p>₹ {itemDetails.consuntantFee || 0}  fee</p>
                            </div>
                        </div>
                    </div>

                    <h5>{itemDetails?.clinic?.firstName} {itemDetails?.clinic?.lastName}</h5>
                    <p>4 <span className="icon-star"></span>  &nbsp;&nbsp;&nbsp; 15 mins wait</p>
                    <span>{itemDetails?.clinic?.gaunpalikaDetail?.gaunpalika_name}</span>
                    <hr />

                    <DateSlider navigate={this.props.router.navigate} router={this.props.router} dates={availabilityDetails} doctorAppointments={doctorAppointments} itemDetails={itemDetails} userDetails={userDetails}
                    appointmentOTPGenerate={this.props.appointmentOTPGenerate} appointmentVerifyAndCreate={this.props.appointmentVerifyAndCreate}
                    />

                    {/* <ul className="sidebar__working-hours-list">
                        <li><Link to={process.env.PUBLIC_URL + `/`}>Saturday <span>Closed</span></Link></li>
                        <li><Link to={process.env.PUBLIC_URL + `/`}>Sunday <span>9 AM - 5 PM</span></Link></li>
                        <li><Link to={process.env.PUBLIC_URL + `/`}>Monday <span>9 AM - 5 PM</span></Link></li>
                        <li><Link to={process.env.PUBLIC_URL + `/`}>Tuesday <span>9 AM - 5 PM</span></Link></li>
                        <li><Link to={process.env.PUBLIC_URL + `/`}>Wednesday <span>9 AM - 5 PM</span></Link></li>
                        <li><Link to={process.env.PUBLIC_URL + `/`}>Thursday <span>9 AM - 5 PM</span></Link></li>
                        <li><Link to={process.env.PUBLIC_URL + `/`}>Friday <span>Closed</span></Link></li>
                    </ul> */}
                </div>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
  
    };
  }
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      { ...appActions },
      dispatch
    );
  }
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        ListingWorkingHours
    )
  );
  
  