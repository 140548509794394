import React from 'react';
import { Link } from "react-router-dom";
import LanguageSwitcher from './LanguageSwitcher';
import Nav from './Nav';
import SearchButton from './SearchButton';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import withRouter from '../../utils/withRouterCustom';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import { endSession, getStorageUser } from '../../utils/auth';
import { notifyUser } from '../../utils/notification-service';
import InviteModal from './InviteModal';
import config from '../../config';
const items = [
    {
        label: <Link to={process.env.PUBLIC_URL + `/account`}>My Account</Link>,
        key: '0',
    },
    {
        label: <Link to={process.env.PUBLIC_URL + `/appointments`}>My Appointments</Link>,
        key: '2',
    },
    // {
    //     label: <Link to={process.env.PUBLIC_URL + `/transactions`}>Transactions</Link>,
    //     key: '3',
    // },
    {
        label: <span style={{ width: '100%' }} onClick={() => console.log('looooo')}>Logout</span>,
        key: '1',
    },
];
class HeaderOne extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            open: false,
            otherInfo:{}
        }
    }

    componentDidMount() {
        let user = getStorageUser();

        if (user) {
            this.props.setUserAction(JSON.parse(user));
        }
        else {
            this.props.setUserAction(null);
        }
        this.props.getOtherInfo(1).then((resp) => {

            if (resp && !resp.error) {
                this.setState({ otherInfo: JSON.parse(resp.configData) });
            }
        }).catch((ex) => {
            console.log({ ex });
        })
    }

    setInvitationModal = (val) => {
        this.setState({ open: val })
    }
    handleClick = (e) => {
        console.log('e', e);
        if (e.key == 1) {
            endSession();
            notifyUser('You have been logged out', 'info', 3);
            this.props.setUserAction(null);
            this.props.router.navigate('/');
        }

    }
    render() {
        const { user } = this.props;
        console.log('this.props.user', this.props.user);
        let publicUrl = process.env.PUBLIC_URL + '/';
        let { otherInfo } = this.state;
        return (
            <>
                <InviteModal
                    setInvitationModal={this.setInvitationModal}
                    open={this.state.open}
                    sendInvitation={this.props.sendInvitation}

                />
                <header class="main-header main-header-one clearfix">
                    <div class="main-header-one__wrapper">
                        <div class="main-header-one__top">
                            <div class="container">
                                <div class="main-header-one__top-inner">
                                    <div class="main-header-one__top-address">
                                        <ul>
                                            <li>
                                                {/* <div class="icon"><span class="icon-pin"></span></div>
                                                <div class="text"><p>27/52 Avenue, NY USA 685.</p></div> */}
                                            </li>

                                            <li>
                                                {/* <div class="icon"><span class="icon-clock"></span></div>
                                                <div class="text"><p>Mon - Sat 8.00 - 18.00. Sun Closed</p></div> */}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="main-header-one__top-social-link">
                                        <ul>
                                            <li><a href="#"><i class="icon-facebook-app-symbol"></i></a></li>
                                            <li><a href="#"><i class="icon-pinterest"></i></a></li>
                                            <li><a href="#"><i class="icon-twitter"></i></a></li>
                                            <li><a href="#"><i class="icon-instagram"></i></a></li>
                                            {user && <li style={{ marginLeft: '20px' }}>
                                                <Dropdown menu={{ items, onClick: this.handleClick }} trigger={['click']} >
                                                    <a className='welcome-btn' onClick={(e) => e.preventDefault()} style={{ cursor: 'pointer' }}>
                                                        <Space>
                                                            Welcome {user.firstName}
                                                            <DownOutlined />
                                                        </Space>
                                                    </a>
                                                </Dropdown>
                                            </li>
                                            }

                                        </ul>

                                    </div>
                                    {!user && <Link to='/login' style={{ color: 'white', marginLeft: '5%' }}>Login</Link>}
                                    {!user && <Link to='/signup' style={{ color: 'white', marginLeft: '1%' }}>Sign-Up</Link>}
                                    <span style={{ color: 'white', marginLeft: '3%', cursor: 'pointer' }} onClick={() => this.setInvitationModal(true)}>List Your Clinic</span>
                                </div>
                            </div>
                        </div>

                        <div class="main-header-one__bottom">
                            <nav class="main-menu main-menu--1">
                                <div class="main-menu__wrapper">
                                    <div class="container">
                                        <div class="main-menu__wrapper-inner">
                                            <div class="main-header-one__bottom-left">
                                                <div class="logo">
                                                    <Link to={process.env.PUBLIC_URL + `/`}><img src={otherInfo.siteLogo && otherInfo.siteLogo[0] && otherInfo.siteLogo[0].url ? (config.APIURL1 + '/' + (otherInfo && otherInfo.siteLogo && otherInfo.siteLogo[0] && otherInfo.siteLogo[0].url)) : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt="" style={{maxHeight:'8vh'}}/></Link>
                                                </div>
                                            </div>

                                            <div class="main-header-one__bottom-right">
                                                <Nav />

                                                {/* <div class="main-header-one__bottom-right-search">
                                                    <SearchButton />
                                                </div> */}

                                                {/* <LanguageSwitcher /> */}

                                                {/* <div class="main-header-one__bottom-right-btn">
                                                    <Link to={process.env.PUBLIC_URL + `/`} class="thm-btn">+ Add Listing </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        ...state.user
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        HeaderOne
    )
);

