import React, { useState } from 'react';
import HeaderTwo from '../common/header/HeaderTwo';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import ListingDetails from '../components/listing/ListingDetails'
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';

const ListingsDetails = () => {
    const [details,setDetails]=useState({});
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading={details?.firstName+ ' '+ details?.lastName}
                currentPage={details?.firstName+ ' '+ details?.lastName+ ' Details'}
            />
            <ListingDetails 
            setDetails={setDetails}
            />
            <FooterOne />
        </>
    )
}

export default ListingsDetails;