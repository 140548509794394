import React from 'react';
import * as appActions from '../../redux/actions/app-actions';
import withRouter from '../../utils/withRouterCustom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ContactTwo extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            otherInfo: {}

        }
    }


    componentDidMount() {

        this.props.getOtherInfo(1).then((resp) => {

            if (resp && !resp.error) {
                this.setState({ otherInfo: JSON.parse(resp.configData) });
            }
        }).catch((ex) => {
            console.log({ ex });
        })
    }
    render() {
        let {otherInfo}=this.state;
        return (
            <>
                <section className="contact-one contact-page">
                    <div className="container">
                        <div className="row">
                            {/* Start Contact One Form */}
                            <div className="col-xl-8">
                                <div className="contact-one__form">
                                    <div className="contact-one__form-text">
                                        <h2>Our Contact</h2>
                                        <pre>{otherInfo.contactUsBody}</pre>
                                    </div>
                                    <form action="#" className="comment-one__form contact-form-validated" novalidate="novalidate">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="comment-form__input-box">
                                                    <input type="text" placeholder="Your Name" name="name" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="comment-form__input-box">
                                                    <input type="email" placeholder="Your Email" name="email" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="comment-form__input-box">
                                                    <input type="text" placeholder="Phone" name="phone" />
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6">
                                                <div className="comment-form__input-box">
                                                    <input type="text" placeholder="Subject" name="subject" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12 col-lg-12 col-md-12">
                                                <div className="comment-form__input-box">
                                                    <textarea name="message" placeholder="Message"></textarea>
                                                </div>
                                                <button className="thm-btn comment-form__btn" type="submit" data-loading-text="Please wait...">
                                                    Submit Now
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            {/* End Contact One Form */}

                            {/* Start Contact One Content */}
                            <div className="col-xl-4">
                                <div className="contact-one__content">
                                    <div className="contact-one__content-single">
                                        <div className="contact-one__content-single-inner">
                                            <div className="icon-box">
                                                <span className="icon-call"></span>
                                            </div>

                                            <div className="content-box">
                                                <h2>{otherInfo.contact1Title}</h2>
                                                <pre>{otherInfo.contact1Content}</pre>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="contact-one__content-single">
                                        <div className="contact-one__content-single-inner">
                                            <div className="icon-box">
                                                <span className="icon-email-1"></span>
                                            </div>

                                            <div className="content-box">
                                            <h2>{otherInfo.contact2Title}</h2>
                                                <pre>{otherInfo.contact2Content}</pre>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="contact-one__content-single">
                                        <div className="contact-one__content-single-inner">
                                            <div className="icon-box">
                                                <span className="icon-address"></span>
                                            </div>

                                            <div className="content-box">
                                            <h2>{otherInfo.contact3Title}</h2>
                                                <pre>{otherInfo.contact3Content}</pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Contact One Content */}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.user
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        ContactTwo
    )
);

