import React from 'react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import AboutTwo from '../components/about/AboutTwo';
import VisitedOne from '../components/visited/VisitedOne';
import TeamOne from '../components/team/TeamOne';
import CounterTwo from '../components/counter/CounterTwo';
import FeatureOne from '../components/features/FeatureOne';
import VideoOne from '../components/video/VideoOne';
import BrandOne from '../components/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import AboutOne from '../components/about/AboutOne';

const About = () => {
    return (
        <>
            <HeaderOne />
            <Breadcrumb 
                heading="About Page"
                currentPage="About Page" 
            />
            {/* <AboutTwo /> */}
            <AboutOne
            />
            {/* <VisitedOne />
            <TeamOne />
            <CounterTwo /> */}
            {/* <FeatureOne /> */}
            {/* <VideoOne />
            <BrandOne /> */}
            <FooterOne />
        </>
    )
}

export default About;