import React from 'react';
import { Link } from 'react-router-dom';
import withRouter from '../../utils/withRouterCustom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import config from '../../config';
class FooterOne extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            otherInfo: {}
        }
    }

    componentDidMount() {

        this.props.getOtherInfo(1).then((resp) => {

            if (resp && !resp.error) {
                this.setState({ otherInfo: JSON.parse(resp.configData) });
            }
        }).catch((ex) => {
            console.log({ ex });
        })

    }



    render() {
        let publicUrl = process.env.PUBLIC_URL + '/';
        let {otherInfo}=this.state;
        return (
            <>
                {/* Start Footer One */}
                <footer className="footer-one">
                    <div className="footer-one__top">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__top-inner">
                                        <div className="row">

                                            {/* Start Footer Widget Column */}
                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
                                                <div className="footer-widget__column footer-widget__about">
                                                    <div className="footer-widget__about-logo">
                                                        <Link to={process.env.PUBLIC_URL + `/`}><img  src={otherInfo.siteLogo && otherInfo.siteLogo[0] && otherInfo.siteLogo[0].url ? (config.APIURL1 + '/' + (otherInfo && otherInfo.siteLogo && otherInfo.siteLogo[0] && otherInfo.siteLogo[0].url)) : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} alt="" /></Link>
                                                    </div>

                                                    <ul className="footer-widget__about-contact-info">
                                                        <li>
                                                            <div className="icon">
                                                                <span className="fa fa-clock"></span>
                                                            </div>
                                                            <div className="text">
                                                                <p>{otherInfo.text1} <br /> {otherInfo.text2} </p>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="icon">
                                                                <span className="fa fa-map-marker"></span>
                                                            </div>
                                                            <div className="text">
                                                                <p>{otherInfo.text3} </p>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <div className="icon">
                                                                <span className="fa fa-phone rotate"></span>
                                                            </div>
                                                            <div className="text">
                                                                <p><a href="tel:123456789">{otherInfo.text4} </a></p>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                    <div className="footer-widget__about-social-link">
                                                        <ul>
                                                            <li>
                                                                <a href="#">
                                                                    <span className="first icon-facebook-app-symbol"></span>
                                                                    <span className="second icon-facebook-app-symbol"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <span className="first icon-pinterest"></span>
                                                                    <span className="second icon-pinterest"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <span className="first icon-twitter"></span>
                                                                    <span className="second icon-twitter"></span>
                                                                </a>
                                                            </li>

                                                            <li>
                                                                <a href="#">
                                                                    <span className="first icon-linkedin"></span>
                                                                    <span className="second icon-linkedin"></span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer Widget Column */}

                                            {/* Start Footer Widget Column */}
                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
                                                <div className="footer-widget__column footer-widget__services">
                                                    <h2 className="footer-widget__title">Services</h2>
                                                    <ul className="footer-widget__services-list">
                                                        <li className="footer-widget__services-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Why choose us</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Our solutions</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Partners</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Core values</Link></li>
                                                        <li className="footer-widget__services-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Our projects</Link> </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* End Footer Widget Column */}

                                            {/* Start Footer Widget Column */}
                                            <div className="col-xl-2 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
                                                <div className="footer-widget__column footer-widget__link">
                                                    <h2 className="footer-widget__title">Quick Link</h2>
                                                    <ul className="footer-widget__link-list">
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Residents</Link></li>
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Business</Link></li>
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Online Service</Link></li>
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Visiting</Link></li>
                                                        <li className="footer-widget__link-list-item"><Link to={process.env.PUBLIC_URL + `/`}>Employment</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* End Footer Widget Column */}

                                            {/* Start Footer Widget Column */}
                                            <div className="col-xl-4 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                                                <div className="footer-widget__column footer-widget__map">
                                                    <h2 className="footer-widget__title">City Map</h2>
                                                    <div className="footer-widget__map-box">
                                                        <iframe src={otherInfo.mapURL} className="footer-widget-map__one" allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer Widget Column */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Start Footer One Bottom */}
                    <div className="footer-one__bottom clearfix">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="footer-one__bottom-inner text-center">
                                        <div className="footer-one__bottom-text">
                                            <p>Copyright © 2022 <Link to={process.env.PUBLIC_URL + `/`}>Clinics Nepal.</Link> All Rights Reserved.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Footer One Bottom */}
                </footer>
                {/* End Footer One */}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.user
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        FooterOne
    )
);
