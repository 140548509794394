import { Button, Spin, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { notifyUser } from '../../utils/notification-service';
import { getStorageUser } from '../../utils/auth';
import withRouter from '../../utils/withRouterCustom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../../redux/actions/app-actions';
import moment from 'moment';
import PostReview from './PostReview';
import RenderReview from '../shared/renderReview';

const AppointmentList = (props) => {
    let publicUrl = process.env.PUBLIC_URL + '/';

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsOpenModal] = useState(null);

    const user = getStorageUser();
    let userDetails = user ? JSON.parse(user) : null;

    useEffect(() => {

        initializer();

    }, []);

    const initializer = () => {
        setLoading(true);
        props.getMyAppointments(userDetails.id).then((resp) => {

            if (resp && !resp.error) {
                setList(resp);
                setLoading(false);
            }
            else {
                notifyUser(resp.message || "Something went wrong!", 'error');
                setLoading(false);
            }

        }).catch((err) => {
            notifyUser(err.message || "Something went wrong!", 'error');
            setLoading(false);
        })
    }

    const cancelAppointment = (id) => {


        props.cancelAppointment(id, { status: 1 }).then((resp) => {

            if (resp && resp.id) {
                notifyUser("Appointment Cancelled", 'success', 4);
                initializer();
            }
            else {
                notifyUser("Something went wrong!", 'warning', 7);
                setLoading(false);
            }

        }).catch((err) => {
            notifyUser(err.message || "Something went wrong!", 'error', 7);
            setLoading(false);
        })

    }
    const handleReviewModal = (val) => {
        setIsOpenModal(val);
    }

    const postReviewFunction = (args) => {

        return new Promise((resolve, reject) => {


            props.postReview(args).then((resp) => {


                if (resp && resp.id) {
                    resolve(resp);
                }
                else {
                    reject({ message: resp.message || "Something went wrong" });
                }

            }).catch((err) => {

                reject({ message: err.message || "Something went wrong" });


            })


        })

    }
    return (
        <>

            <Spin spinning={loading}>
                <section className="listings_three-page container mt-4 one">
                    {isModalOpen && <PostReview
                        handleReviewModal={handleReviewModal}
                        isModalOpen={isModalOpen}
                        postReviewFunction={postReviewFunction}
                        initializer={initializer}

                    />
                    }
                    <div className="row">
                        <div className="col-xl-12">
                            {/* Start Place One Single */}
                            <div className="place-one__single place-one__single--two">
                                <div className="row">

                                    {(list || []).map(item =>

                                        <div className="col-xl-6 col-lg-6 col-md-12 my-4">
                                            <div className="place-one__single-content">
                                                <div className='sky-clock'>
                                                    <span><i className="fa fa-clock"></i> {moment(item.date, 'MM-DD-YYYY').format('ddd, MMM DD YYYY')} at {item.time}</span>
                                                </div>
                                                <div className="top-content">
                                                    <h2>{item.firstName} {item.lastName}</h2>
                                                    <p>{'Dr ' + item.doctor?.firstName} {item.doctor?.lastName}</p>

                                                    {/* <div className="icon-sky">
                                                        <Button type='link'><span className="fa fa-phone"></span></Button>
                                                    </div> */}
                                                </div>

                                                <div className="facilities-box">
                                                    <div className="title-a">
                                                        <h6>Clinic</h6>
                                                        <p>{item.clinic?.firstName} {item.clinic?.lastName}</p>
                                                    </div>

                                                    <div className="title-a">
                                                        <h6>Amount</h6>
                                                        <p>{item.doctor?.consuntantFee} </p>
                                                    </div>

                                                    <div className="title-a">
                                                        <h6>Status</h6>
                                                        <p className='green'>{(item.status == 0 || !item.status) ? "Upcoming" : item.status == 1 ? "Cancelled" : "Completed"}</p>
                                                    </div>
                                                </div>

                                                <div className="bottom-content">
                                                    {/* <div className="sky-title">
                                                        <Button type="link">Reschedule Request</Button>
                                                    </div> */}

                                                    {(item.status == 0 || !item.status) && <div className="sky-title">
                                                        <Popconfirm
                                                            title="Cancel appointment"
                                                            description="Are you sure to cancel this appointment?"
                                                            okText="Yes"
                                                            cancelText="No"
                                                            onConfirm={() => cancelAppointment(item.id)}
                                                        >
                                                            <Button type="link">Cancel Request</Button>
                                                        </Popconfirm>
                                                    </div>
                                                    }
                                                    {item.status == 2 && !item.review && <div className="sky-title">
                                                        <Button type="link" onClick={() => handleReviewModal(item)}>Post Review</Button>
                                                    </div>
                                                    }
                                                    {item.review && item.review !== 'null' &&
                                                        <div>
                                                            <h5 style={{ marginBottom: '20px' }}>Review</h5>
                                                            <RenderReview
                                                                review={item.review}
                                                            />

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!loading && (list || []).length == 0 && <h3>No records found</h3>}

                                </div>
                            </div>
                            {/* End Place One Single */}
                        </div>
                    </div>
                </section>
            </Spin>
        </>
    )
}
function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        AppointmentList
    )
);

