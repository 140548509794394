import React from 'react';
import {Link} from "react-router-dom";
import MobileMenu from './MobileMenu';

const Nav = () => {
    return (

        <>
            <div class="main-menu__inner">
                <MobileMenu />
                <ul class="main-menu__list">
                    <li>
                        <Link to={process.env.PUBLIC_URL + `/`}>Home</Link>
                        {/* <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/`}>Home One</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Home Two</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Home Three</Link></li>
                            <li class="dropdown">
                                <Link to={process.env.PUBLIC_URL + `#`}>Header Styles</Link>
                                <ul>
                                    <li><Link to={process.env.PUBLIC_URL + `/`}>Header One</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Header Two</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Header Three</Link></li>
                                </ul>
                            </li>
                        </ul> */}
                    </li>
                    <li><Link to={process.env.PUBLIC_URL + `/listings`}>All Doctors</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/about`}>About Us</Link></li>
                    <li><Link to={process.env.PUBLIC_URL + `/contact`}>Contact Us</Link></li>
                    {/* <li><Link to={process.env.PUBLIC_URL + `/about`}>About</Link></li>
                    <li class="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Listings</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/listings1`}>Listings One</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/listings2`}>Listings Two</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/listings3`}>Listings Three</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/listings-details`}>Listings Details</Link></li>
                        </ul>
                    </li>
                    <li class="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Pages</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/categories`}>Categories</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/visited`}>visited</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/team`}>Team</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/team-details`}>Team Details</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/error`}>Error</Link></li>
                        </ul>
                    </li>
                    <li class="dropdown">
                        <Link to={process.env.PUBLIC_URL + `#`}>Blog</Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/blog`}>Blog</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}>Blog Details</Link></li>
                        </ul>
                    </li>
                    <li><Link to={process.env.PUBLIC_URL + `/contact`}>Contact</Link></li> */}
                </ul>
            </div>
        </>
    )
}

export default Nav;


