import React from 'react';

export default class ContactMap extends React.Component {

    constructor(props){

        super(props);

        this.state={

        }
    }



    
    render(){
        let {mapURL}=this.props;
        return (
            <>

                <section className="google-map">
                    <iframe
                    title="Map"
                    src={mapURL}
                    className="google-map__one" allowfullscreen></iframe>
                </section>
            </>
        )
    }
}