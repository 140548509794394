import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import ContactTwo from '../components/contact/ContactTwo';
import ContactMap from '../components/contact/ContactMap';
import BrandOne from '../components/brand/BrandOne';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import withRouter from '../utils/withRouterCustom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as appActions from '../redux/actions/app-actions';
const Contact = (props) => {

    const [otherInfo, setOtherInfo] = useState(null);
    const [loading, setloading] = useState(true);
    const ref = useRef();
    useEffect(() => {


        props.getOtherInfo(1).then((resp) => {

            ref.current = 1;
            if (resp && !resp.error) {
                setOtherInfo(JSON.parse(resp.configData));
                setTimeout(() => {
                    setloading(false);
                }, 700)

            }
        }).catch((ex) => {
            console.log({ ex });
        })


    }, [])

    console.log({ otherInfo })
    return (
        <>
            <HeaderOne />
            <Breadcrumb
                heading="Contact Page"
                currentPage="Contact Page"
            />
            <ContactTwo />
            {!loading &&
                <ContactMap
                mapURL={otherInfo && otherInfo.mapURL}
                />
            }
            {/* <BrandOne /> */}
            <FooterOne />
        </>
    )
}


function mapStateToProps(state) {
    return {

    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        Contact
    )
);

