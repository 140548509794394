import React, { useEffect, useState } from "react";
import HeroOne from "../components/hero/HeroOne";
import CategoriesOne from "../components/categories/CategoriesOne";
import VisitedOne from "../components/visited/VisitedOne";
import FeatureOne from "../components/features/FeatureOne";
import VideoOne from "../components/video/VideoOne";
import TestimonialOne from "../components/testimonial/TestimonialOne";
import BlogOne from "../components/blog/BlogOne";
import BrandOne from "../components/brand/BrandOne";
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from "../common/header/HeaderOne";



const HomeDefault = () => {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
    })

    return (
        <>
            <HeaderOne />
            <HeroOne />
            <FeatureOne />
            {/* <CategoriesOne /> */}
            {/* <VisitedOne /> */}
            {/* <VideoOne /> */}
            {!loading && <TestimonialOne
                setLoading={setLoading}
            />
            }
            {/* <BlogOne /> */}
            {/* <BrandOne /> */}
            <FooterOne />
        </>
    )
}

export default HomeDefault;