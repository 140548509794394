import Axios from "../../utils/axiosInstance";



const getClinics = () => {
    return Axios.get("/app/clinics", undefined, undefined);
};

const getGaunpalikas = string => {
    return Axios.get("/app/gaunpalikas/" + string, undefined, undefined);
};
const getDoctorsClinicsByGaunPalika = (id, string) => {
    return Axios.get("/app/gaunpalikas/" + id + '/' + string, undefined, undefined);
};
const getSpecialities = string => {
    return Axios.get("/app/specialities?string=" + string, undefined, undefined);
};
const getDoctorsBySpecialityId = (specialityId, filters) => {
    return Axios.get("/app/doctorsClinicsBySpeciality/" + specialityId + '?filters=' + JSON.stringify(filters), undefined, undefined);
};
const getDocDetails = id => {
    return Axios.get("/app/docDetails/" + id, undefined, undefined);
};
const getClinicDetails = id => {
    return Axios.get("/app/clinicDetails/" + id, undefined, undefined);
};
const getClinicDoctorsByState = id => {
    return Axios.get("/app/clinicsByState/" + id, undefined, undefined);
};
const getOtherInfo = id => {
    return Axios.get("/app/otherinfo/" + id, undefined, undefined);
};
const getTestimonials = () => {
    return Axios.get("/app/testimonials", undefined, undefined);
};

const loginViaGoogle = (payload) => {
    return Axios.post("/app/loginviagoogle", payload, undefined);
};

const signup = (payload) => {
    return Axios.post("/app/signup", payload, undefined);
};


const verifyOTP = (payload) => {
    return Axios.post("/app/verifyotp", payload, undefined);
};

const resendOTP = (payload) => {
    return Axios.post("/app/resendotp", payload, undefined);
};
const login = (payload) => {
    return Axios.post("/app/login", payload, undefined);
};

const getMyProfile = (id) => {
    return Axios.get("/app/myprofile/" + id, undefined, undefined);
};
const updateMyProfile = (id, data) => {
    return Axios.put("/app/myprofile/" + id, data, undefined);
};
const sendInvitation = (data) => {
    return Axios.post("/miscellaneous/sendBulkInvitations", data, undefined);
};

const getAvailabilityDetails = (id) => {
    return Axios.get("/app/getAppointmentSlots/" + id, undefined, undefined);
};

const appointmentOTPGenerate = (userId, data) => {
    return Axios.post("/app/appointmentOTP/" + userId, data, undefined);
};
const appointmentVerifyAndCreate = (data) => {
    return Axios.post("/app/appointmentVerifyAndCreate", data, undefined);
};
const getMyAppointments = (id) => {
    return Axios.get("/app/myAppointments/" + id, undefined, undefined);
};
const cancelAppointment = (id, status) => {
    return Axios.put("/app/appointment-complete/" + id, status, undefined);
};
const postReview = (payload) => {
    return Axios.post("/app/postReview", payload, undefined);
};
const getDocReviews = (id) => {
    return Axios.get("/app/docReviews/" + id, undefined, undefined);
};
const getStatesOfNepal = () => {
    return Axios.get("/app/states", undefined, undefined);
};
const reviewsClinic = (id) => {
    return Axios.get("/app/reviewsClinic/" + id, undefined, undefined);
};

const sendResetPasswordOTP = (payload) => {
    return Axios.post("/app/sendResetPasswordOTP", payload, undefined);
};

const changePasswordViaOTP = (payload) => {
    return Axios.post("/app/changepassword", payload, undefined);
};



export default {
    changePasswordViaOTP,
    sendResetPasswordOTP,
    reviewsClinic,
    getStatesOfNepal,
    getDocReviews,
    postReview,
    cancelAppointment,
    getMyAppointments,
    appointmentVerifyAndCreate,
    appointmentOTPGenerate,
    getAvailabilityDetails,
    sendInvitation,
    updateMyProfile,
    getMyProfile,
    login,
    resendOTP,
    verifyOTP,
    signup,
    loginViaGoogle,
    getTestimonials,
    getOtherInfo,
    getClinicDoctorsByState,
    getClinics,
    getClinicDetails,
    getDocDetails,
    getSpecialities,
    getGaunpalikas,
    getDoctorsClinicsByGaunPalika,
    getDoctorsBySpecialityId
};
