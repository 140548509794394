import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import * as AiIcons from 'react-icons/ai';
import { AiOutlineBars } from 'react-icons/ai';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import withRouter from '../../utils/withRouterCustom';
import * as appActions from '../../redux/actions/app-actions';
import { endSession } from '../../utils/auth';
import { notifyUser } from '../../utils/notification-service';
import InviteModal from './InviteModal';
import config from '../../config';
const menuData = [
    {
        title: 'Home',
        path: '/',
        // iconClosed: <CgChevronLeft />,
        // iconOpened: <CgChevronDown />,

        // subNav: [
        //     {
        //         title: 'Home One',
        //         path: '/',
        //     },
        //     {
        //         title: 'Home Two',
        //         path: '/home-two',
        //     },
        //     {
        //         title: 'Home Three',
        //         path: '/home-three',
        //     },
        // ],
    },
    {
        title: 'All Doctors',
        path: '/listings',
    },
    {
        title: 'About Us',
        path: '/about',
    },
    {
        title: 'Contact Us',
        path: '/contact',
    },
];

const items = [
    {
        label: <Link to={process.env.PUBLIC_URL + `/account`}>My Account</Link>,
        key: '0',
    },
    {
        label: <Link to={process.env.PUBLIC_URL + `/appointments`}>My Appointments</Link>,
        key: '2',
    },
    {
        label: <span style={{ width: '100%' }} onClick={() => console.log('looooo')}>Logout</span>,
        key: '1',
    },
];

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    &:hover {
        background: #ff6f0f;
        color: #fff;
        cursor: pointer;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
    color: #fff;
    font-weight: 400;
`;

const DropdownLink = styled(Link)`
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    &:hover,
    &:active {
        background: #ff6f0f;
        cursor: pointer;
        color: #fff;
    }
`;

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (
        <>
            <SidebarLink to={process.env.PUBLIC_URL + `${item.path}`} onClick={item.subNav && showSubnav}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                </div>
            </SidebarLink>
            {subnav &&
                item.subNav.map((data, index) => (
                    <DropdownLink to={process.env.PUBLIC_URL + `${data.path}`} key={index}>
                        {item.icon}
                        <SidebarLabel>{data.title}</SidebarLabel>
                    </DropdownLink>
                ))}
        </>
    );
};

const NavIcon = styled(Link)`
    color: #ff6f0f;
    font-size: 2rem;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 20px;
`;

const SidebarNav = styled.nav`
    background-color: #150b5c;
    width: 300px;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
    top: 0;
    right: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 99999;
`;

const SidebarWrap = styled.div`
    width: 100%;
`;

const MobileMenu = (props) => {
    const { user } = props;
    const [sidebar, setSidebar] = useState(false);
    const [open, setInvitationModal] = useState(false);
    const [otherInfo, setOtherInfo] = useState({});
    const showSidebar = () => setSidebar(!sidebar);
    let publicUrl = process.env.PUBLIC_URL + '/'



    useEffect(() => {


        props.getOtherInfo(1).then((resp) => {

            if (resp && !resp.error) {
                setOtherInfo(JSON.parse(resp.configData));
            }
        }).catch((ex) => {
            console.log({ ex });
        })

    }, []);

    const handleClick = (e) => {

        if (e.key == 1) {
            endSession();
            notifyUser('You have been logged out', 'info', 3);
            props.setUserAction(null);
            props.router.navigate('/');
        }

    }



    return (
        <>
            <InviteModal
                setInvitationModal={setInvitationModal}
                open={open}
                sendInvitation={props.sendInvitation}

            />
            <>
                <NavIcon className='sky-mobile-menu' to="#" style={{ justifyContent: 'flex-end' }}>
                    <AiOutlineBars onClick={showSidebar} />
                </NavIcon>

                <SidebarNav className='mobile-nav-content' sidebar={sidebar}>
                    <SidebarWrap>
                        <div className="mobile-nav__content">
                            <div className="logo-box">
                                <Link to={process.env.PUBLIC_URL + `/`} aria-label="logo image"><img src={otherInfo.siteLogo && otherInfo.siteLogo[0] && otherInfo.siteLogo[0].url ? (config.APIURL1 + '/' + (otherInfo && otherInfo.siteLogo && otherInfo.siteLogo[0] && otherInfo.siteLogo[0].url)) : 'https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg'} width="155" alt="" /></Link>
                            </div>
                            <NavIcon to="#" className='sky-close'>
                                <AiIcons.AiOutlineClose
                                    style={{
                                        color: 'white',
                                        fontSize: '18px',
                                        justifyContent: 'flex-start',
                                    }}
                                    onClick={showSidebar}
                                />
                            </NavIcon>
                            {menuData.map((item, index) => (
                                <SubMenu item={item} key={index} />
                            ))}
                            <ul className="mobile-nav__contact list-unstyled">
                                {/* <li>
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:needhelp@packageName__.com">needhelp@const.com</a>
                                </li>
                                <li>
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <a href="tel:666-888-0000">666 888 0000</a>
                                </li> */}
                                {user &&
                                    <li style={{ marginLeft: '20px' }}>
                                        <Dropdown menu={{ items, onClick: handleClick }} trigger={['click']} >
                                            <a className='welcome-btn' onClick={(e) => e.preventDefault()} style={{ cursor: 'pointer' }}>
                                                <Space>
                                                    Welcome {user.firstName}
                                                    <DownOutlined />
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </li>
                                }
                                <li>
                                    {!user && <Link to='/login' style={{ color: 'white', marginLeft: '1%' }}>Login</Link>}
                                </li>
                                <li>
                                    {!user && <Link to='/signup' style={{ color: 'white', marginLeft: '1%' }}>Sign-Up</Link>}
                                </li>
                                <li>
                                    <span style={{ color: 'white', marginLeft: '1%', cursor: 'pointer' }} onClick={() => setInvitationModal(true)}>List Your Clinic</span>
                                </li>
                            </ul>
                            <div className="mobile-nav__top">
                                <div className="mobile-nav__social">
                                    <a href="https://twitter.com/"><span className="fab fa-twitter"></span></a>
                                    <a href="https://facebook.com/"><span className="fab fa-facebook-square"></span></a>
                                    <a href="https://pinterest.com/"><span className="fab fa-pinterest-p"></span></a>
                                    <a href="https://instagram.com/"><span className="fab fa-instagram"></span></a>
                                </div>
                            </div>
                        </div>
                    </SidebarWrap>
                </SidebarNav>
            </>
        </>
    );
};
function mapStateToProps(state) {
    return {
        ...state.user
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { ...appActions },
        dispatch
    );
}
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        MobileMenu
    )
);
