import { Select, Spin } from 'antd';
import React, { Component } from 'react'
class HeaderSearch extends Component {
    constructor(props) {
        super(props);
    }
    state = {}
    render() {
        const { categories, doctors, gaunPalikas, searching,enteredString } = this.props;

        console.log('categories',categories)
        return (
            <form action="#" className="comment-one__form contact-form-validated sky-search" novalidate="novalidate">
                <ul>
                    <li>
                        {/* <div className="comment-form__input-box">
                        <div className="icon">
                            <span className="icon-pin"></span>
                        </div>
                        <input type="text" placeholder="Location" name="name" />
                    </div> */}

                        <Select
                        id="gaunpalikas"
                            defaultValue={this.props.selectedGaunpalika}
                            style={{ width: '100%', height: '100%' }}
                            showSearch
                            labelInValue
                            filterOption={false}
                            placeholder="Location"
                            onChange={this.props.onUserSelect}
                            onSearch={(evt) => this.props.onUserInput(evt)}
                            // options={users}
                            notFoundContent={searching ? <Spin size="small" /> : <span>No results</span>}
                        >
                            {gaunPalikas.length > 0 && gaunPalikas.map(function (item) {
                                return (
                                    <Select.Option key={item.id} value={item.id} >
                                        {item.gaunpalika_name}
                                    </Select.Option>
                                );
                            })}

                        </Select>
                    </li>
                    <li>
                        <div className="comment-form__input-box" style={{ height: '100%' }}>
                            {/* <div className="icon">
                                <span className="fas fa-keyboard"></span>
                            </div> */}
                            <Select
                             id="stringsearches"
                            disabled={!this.props.selectedGaunpalika}
                                defaultValue={this.props.selectedString}
                                style={{ width: '100%', height: '100%' }}
                                showSearch
                                labelInValue
                                filterOption={false}
                                placeholder="Search doctors, clinic, Hospitals etc."
                                onChange={this.props.onUserStringSelect}
                                onSearch={(evt) => this.props.onUserStringInput(evt)}
                                onKeyDown={(e)=>{console.log({e})}}
                                // options={users}
                                notFoundContent={searching ? <Spin size="small" /> : <span> {!enteredString || (enteredString && enteredString.length==0) ? "Please enter your search query to find relevant information" : "No results"}</span>}
                            >
                                {categories.length > 0 && !searching &&
                                    <Select.Option key={'categories'} value={'categories'} disabled>
                                        <span>By Categories</span>
                                    </Select.Option>
                                }
                                {categories.length > 0 && !searching &&  categories.map(function (item) {
                                    return (
                                        <Select.Option key={item.id+'categories'} value={item.id}>
                                            - <span>{item.name} </span>
                                        </Select.Option>
                                    );

                                })}

                                {doctors.length > 0 &&
                                    <Select.Option key={'doctors'} value={'doctors'} disabled>
                                        <span>By Doctors/Clinics</span>
                                    </Select.Option>
                                }

                                {doctors.length > 0 && doctors.map(function (item) {
                                    return (
                                        <Select.Option key={item.id+'persons'} value={item.id} title={item.role_id == 2 ? 'Doctor' : item.role_id == 5 ? 'Clinic' : ""}>
                                            {item.role_id == 5 && <> <span>-- {item.firstName} {item.lastName}</span><span style={{ fontWeight: '700' }}> -- Clinic</span></>}
                                            {item.role_id == 2 && <> <span>-- Dr. {item.firstName} {item.lastName}</span><span style={{ fontWeight: '700' }}> --{'Doctor'}</span><span>-- ({item.specialities && item.specialities[0] && item.specialities[0].name})</span></>}

                                        </Select.Option>
                                    );

                                })}

                            </Select>
                            {/* <input type="text" placeholder="Search doctors, clinic, Hospitals etc." name="name" /> */}
                        </div>
                    </li>




                </ul>
            </form>
        );
    }
}

export default HeaderSearch;